import React from 'react'
import { graphql } from 'gatsby'
import SiteNavigation from '../components/header/siteNavigation'
import CommonFooter from '../components/footer/commonFooter'
import Seo from '../components/seo/seo'
import Img from 'gatsby-image'
import SimpleSidebar from '../components/common/simpleSidebar';
import { useTranslation } from 'react-i18next'


const BlogTemplate = ({ data }) => {

  const { t, i18n } = useTranslation()
  
  const currentLanguage = i18n.language

  const filteredServices = data.allServices.edges.filter(({ node }) => node.frontmatter.lang === currentLanguage)

  return(
      <>
        <Seo
          title={data.markdownRemark.frontmatter.title}
          description={data.markdownRemark.frontmatter.headline}
          seoImage={
            data.markdownRemark.frontmatter.seoImage.childImageSharp.resize.src
          }
        />
        <SiteNavigation />
        <div className='bg-gradient-to-r from-indigo-500 via-sky-500 to-emerald-500 flex flex-col md:flex-row'>
          <div className='mt-12 py-12 md:py-48 md:w-1/2 px-8 md:pl-48 md:pr-24'>
            <h1 className="font-semibold text-white text-3xl md:text-4xl">
                {data.markdownRemark.frontmatter.title}
            </h1>
            <div className='mt-8 flex justify-start'>
              <p className="bg-pink-500 rounded-full px-8 py-1 text-white font-semibold">
                {data.markdownRemark.frontmatter.category}
              </p>
            </div>
          </div>
          <div className='h-96 md:h-auto md:w-1/2 relative'>
            <Img
              fluid={data.markdownRemark.frontmatter.seoImage.childImageSharp.fluid}
              style={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
              }}
              imgStyle={{
                  objectFit: 'cover', // Cover the entire div area
                  objectPosition: '50% 50%', // Center the image within the div
                  opacity: 0.2, // Optional: Set the opacity if you want to see anything behind it
              }}
              // className="image-wrapper-height-auto"
              alt={data.markdownRemark.frontmatter.title}
              />
          </div>
        </div>
        <div className='flex flex-col-reverse space-y-8 md:flex-row md:space-x-8 md:space-y-0 pt-8 pb-16 md:max-w-7xl md:mx-auto px-16 xl:px-0'>
              <div className='md:w-1/3 pt-8'>
             <SimpleSidebar services={filteredServices} title={t('blog.blogSidebarTitle')} desc={t('blog.blogSidebarDesc')} cta={t('blog.blogSidebarCTA')} ctaLink={t('blog.blogSidebarLink')} />
          </div>
        <div className='py-8 md:py-16 md:max-w-3xl md:mx-auto px-16 md:px-0 prose prose-xl'>
              <div
                dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
              />        
          
        </div>
        </div>
        
        <CommonFooter />
      </>
    )
  }

export default BlogTemplate

export const blogQuery = graphql`
  query Blog($localizedPath: String!) {
    markdownRemark(fields: { slug: { eq: $localizedPath } }) {
      html
      frontmatter {
        lang
        path
        translatedPath
        title
        headline
        category
        type
        seoImage {
          childImageSharp {
            fluid(maxWidth: 1900) {
              ...GatsbyImageSharpFluid
            }
            resize(width: 600, height: 400) {
              src
            }
          }
        }
      }
    }
    allServices: allMarkdownRemark(
    filter: { frontmatter: { category: { eq: "service" } } }
    sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            lang
          }
        }
      }
    }
  }
`
