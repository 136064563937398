import React from 'react';
import Link from '../../utilities/link'

const Touch = ({ title, description, cta, ctaLink }) => {

  return (
    <div className="my-8 border-b border-r px-8 py-16 bg-emerald-300/25 rounded flex items-center justify-center">
          <div className="flex flex-col space-y-4">
            <div className="flex justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-12 h-12 text-center text-sky-400">
                <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 15.75-2.489-2.489m0 0a3.375 3.375 0 1 0-4.773-4.773 3.375 3.375 0 0 0 4.774 4.774ZM21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
            </div>
            <p className="font-semibold text-xl text-center">{title}</p>
            <p className="text-center">{description}</p>
            <Link to={ctaLink} className="text-center uppercase border-2 border-emerald-500 px-4 py-1 font-semibold" >{cta}</Link>
          </div>
    </div>
  );
};

export default Touch;